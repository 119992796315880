import {TRENDING_PRODUCT} from "../../actions";


export const trendingProduct = (state = [], action) => {
    switch (action.type) {
        case TRENDING_PRODUCT:
            return action.payload;
        default:
            return state
    }
};
