import React, {Component} from 'react';
import Logo from "../../../assets/static/img/logo.png";
import {connect} from "react-redux";
import {getNavigations} from "../../../redux/actions/getNavigationAction";
import {Link} from "react-router-dom";

export class Navbar extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount = async () => {
        await this.props.dispatch(getNavigations());
    };

    render() {
        const {categories} = this.props;
        return (
            <header className="header">
                <div className="header_search">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-12">
                                <nav className="navbar navbar-expand-lg">
                                    <div className="logo mr-auto">
                                        <Link to={"/"}><img src={Logo} alt="logo"/></Link>
                                    </div>
                                    <div className="center_search_box m-auto">
                                        <div className="manin_search_box">
                                            <div className="bar_icon">
                                                <i className="mr mr-menu"/>
                                            </div>
                                            <form action="#">
                                                <div className="search_input">
                                                    <input type="text" className="form-control"
                                                           placeholder="Search for Products"/>
                                                    <select className="catagory_list">
                                                        {categories && categories.length > 0 && categories.map((item, i) =>
                                                            <option value={item.id} key={i}>{item.label}</option>
                                                        )}
                                                    </select>
                                                    <button className="search_btn"><i className="mr mr-search"/>
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="header_right_icon ml-auto">
                                        <ul className="right_iocn_menu">
                                            <li><Link to={"/"}><i className="mr mr-compare"/></Link></li>
                                            <li>
                                                <div className="header_wishlist">
                                                    <Link to="#">
                                                        <i className="mr mr-favorites"/>
                                                        <span className="count_no">3</span></Link>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mini_cart_wrapper">
                                                    <Link to="#">
                                                        <i className="mr mr-shopping-bag"/>
                                                        <span className="amount">BDT 0.00</span>
                                                        <span className="count_no">0</span>
                                                    </Link>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

const mapStateToProps = state => ({
    categories: state.navigationReducer
});

export default connect(mapStateToProps)(Navbar);
