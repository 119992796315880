import api from "../../../../utils/apis/api";
import {products} from "../endPoint";
import {fetchFailure, fetchRequest, fetchSuccess} from "../../../../redux/reducers/commonReducer";
import {PRODUCTS_FAILURE, PRODUCTS_REQUEST, PRODUCTS_SUCCESS} from "../actionType";

function fullUrl(categoryId, subCategoryId, reSubCategoryId) {
    if (categoryId && !subCategoryId && !reSubCategoryId) {
        return `${products}/${categoryId}`;
    } else if (categoryId && subCategoryId && !reSubCategoryId) {
        return `${products}/${categoryId}/${subCategoryId}`;
    } else if (categoryId && subCategoryId && reSubCategoryId) {
        return `${products}/${categoryId}/${subCategoryId}/${reSubCategoryId}`;
    }
}

export const getProductsByCategory = (categoryId, subCategoryId = null, reSubCategoryId = null) => dispatch => {
    dispatch(fetchRequest(PRODUCTS_REQUEST));
    api.get(fullUrl(categoryId, subCategoryId, reSubCategoryId)).then(r => dispatch(fetchSuccess(PRODUCTS_SUCCESS, r.data))).catch(e => dispatch(fetchFailure(PRODUCTS_FAILURE, e)));
};
