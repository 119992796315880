import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import {Link} from 'react-router-dom';
import Logo from "../../../assets/static/img/logo.png";

const FooterTop = () => {
    const navigation = useSelector(state => state.navigationReducer);
    return (
        <div className="footer_top sdfsdfsd">
            <div className="container">
                <div className="row">
                    <div className="col-md-5 col-sm-6 col-12">
                        <div className="footer_contact">
                            <div className="footer_logo">
                                <Link to={"/"}>
                                    <img src={Logo} alt="logo" className="img-fluid"/>
                                </Link>
                            </div>
                            <div className="footer_call_us">
                                <div className="media">
                                        <span className="media_left call_us_icon">
                                            <i className="mr mr-support"/>
                                        </span>
                                    <div className="media_body">
                                        <span className="call_us_text">Got Questions ? Call us 9 AM to 8 PM</span>
                                        <span className="call_us_number">(+88) 02-9110796</span>
                                    </div>
                                </div>
                            </div>
                            <div className="footer_address">
                                <h4 className="footer_address_title">Contact Info</h4>
                                <p className="footer_address_para">13/2, West Panthapath, 5th Floor Dhaka-1207,
                                    Bangladesh. </p>
                            </div>
                            <div className="footer_social">
                                <ul className="social_link">
                                    <li><a href="#"><i className="fab fa-facebook-f"/></a></li>
                                    <li><a href="#"><i className="fab fa-whatsapp"/></a></li>
                                    <li><a href="#"><i className="fab fa-skype"/></a></li>
                                    <li><a href="#"><i className="fab fa-twitter"/></a></li>
                                    <li><a href="#"><i className="fab fa-linkedin-in"/></a></li>
                                    <li><a href="#"><i className="fab fa-instagram"/></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-7 col-sm-6 col-12">
                        <div className="row">
                            <div className="col-md-4 col-sm-6 col-12">
                                <div className="footer_single_item">
                                    <h4 className="footer_title">Find It Fast</h4>
                                    <ul className="footer_menu">
                                        {navigation && navigation.length > 0 && navigation.slice(0, 5).map((item, i) =>
                                            <li key={i}>
                                                <Link to={{
                                                    pathname: `/category/${item.id}/${item.label}`,
                                                    items: item
                                                }}>{item.label}</Link>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6 col-12">
                                <div className="footer_single_item">
                                    <ul className="footer_menu">
                                        {navigation && navigation.length > 0 && navigation.slice(5, 10).map((item, i) =>
                                            <li key={i}>
                                                <Link to={{
                                                    pathname: `/category/${item.id}/${item.label}`,
                                                    items: item
                                                }}>{item.label}</Link>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6 col-12">
                                <div className="footer_single_item">
                                    <h4 className="footer_title">Help</h4>
                                    <ul className="footer_menu">
                                        <li><Link to={"/"}>Contact Us</Link></li>
                                        <li><Link to={"/"}>About Up</Link></li>
                                        <li><Link to={"/"}>Career</Link></li>
                                        <li><Link to={"/"}>Terms &amp; Conditions</Link></li>
                                        <li><Link to={"/"}>Privacy &amp; Policy</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FooterTop;
