import {initialState} from "../../../../redux/reducers/initialState";
import {PRODUCTS_FAILURE, PRODUCTS_REQUEST, PRODUCTS_SUCCESS} from "../actionType";

export const productsReducers = (state = initialState, action) => {
    switch (action.type) {
        case PRODUCTS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PRODUCTS_SUCCESS:
            return {
                loading: false,
                data: action.payload,
                error: ''
            };
        case PRODUCTS_FAILURE:
            return {
                loading: false,
                data: [],
                error: action.payload
            };
        default:
            return state;
    }
};
