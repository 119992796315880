import React, {Component} from 'react'
import FooterNewsLetter from './FooterNewsLetter'
import FooterTop from './FooterTop'


const Footer = () => {
    return (
        <footer className="footer_sec">
            <FooterNewsLetter/>
            <FooterTop/>
        </footer>
    )
}

export default Footer
