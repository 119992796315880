import {SLIDERS} from "../../actions";


export const mainSliderReducer = (state = [], action) => {
    switch (action.type) {
        case SLIDERS:
            return action.payload;
        default:
            return state
    }
};