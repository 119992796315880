import React from 'react';

const Breadcrumb = ({title, categoryName}) => {
    return (
        <>
            <ul className="breadcrumb">
                <li><a href="#">Home</a></li>
                <li><a href="#">{categoryName}</a></li>
                <li><a href="#">{title}</a></li>
            </ul></>
    )
};

export default Breadcrumb;
