import React, {Component} from 'react';
import {connect} from "react-redux";
import {Link} from "react-router-dom";

import {getNavigations} from "../../../redux/actions/getNavigationAction";

class MainMenu extends Component {
    componentDidMount = async () => {
        await this.props.dispatch(getNavigations());
    };

    render() {
        const {navigation} = this.props;
        return (
            <div className="header_main_menu">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-12">
                            <nav className="navbar navbar-expand-lg header_main_navbar">
                                <button className="navbar-toggler" type="button" data-toggle="collapse"
                                        data-target="#main_menu" aria-expanded="false">
                                    <i className="mr mr-menu"/>
                                </button>

                                <div className="collapse navbar-collapse main_menu" id="main_menu">
                                    <ul className="navbar-nav mr-auto">
                                        {navigation && navigation.length > 0 && navigation.slice(0, 7).map((mainMenu, i) =>
                                            <li className={mainMenu.subCategories.length > 0 ? "nav-item dropdown" : "nav-item"}
                                                key={i}>
                                                <Link className="nav-link" to={{
                                                    pathname: `/category/${mainMenu.id}/${mainMenu.label}`,
                                                    items: mainMenu
                                                }}>{mainMenu.label}</Link>
                                                <ul className="dropdown-menu">
                                                    {mainMenu.subCategories && mainMenu.subCategories.map((subMain, k) =>
                                                        <li className={subMain.reSubCategory.length > 0 ? "nav-item dropdown" : "nav-item"}
                                                            key={k}>
                                                            <Link to={{
                                                                pathname: `/category/${mainMenu.id}/${mainMenu.label}/${subMain.subCategoryId}/${subMain.subCatLabel}`,
                                                                items: mainMenu
                                                            }}> {subMain.subCatLabel} </Link>
                                                            <ul className="dropdown-menu">
                                                                {subMain.reSubCategory && subMain.reSubCategory.map((reSubMenu, l) =>
                                                                    <li className="nav-item" key={l}><Link
                                                                        to={{
                                                                            pathname: `/category/${mainMenu.id}/${mainMenu.label}/${subMain.subCategoryId}/${subMain.subCatLabel}/${reSubMenu.reSubCategoryId}/${reSubMenu.reSubCatLabel}`,
                                                                            items: mainMenu
                                                                        }}> {reSubMenu.reSubCatLabel} </Link>
                                                                    </li>
                                                                )}
                                                            </ul>
                                                        </li>
                                                    )}
                                                </ul>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    navigation: state.navigationReducer
});

export default connect(mapStateToProps)(MainMenu);
