import http from "../../../utils/http";
import {categoryProducts, trendingProducts} from "../../../utils/endPoint";
import {CATEGORY_PRODUCTS, TRENDING_PRODUCT} from "../index";

export const getTrendingProducts = () => async dispatch => {
    const res = await http.get(trendingProducts);
    if (res.status === 200) {
        dispatch({type: TRENDING_PRODUCT, payload: res.data});
    } else {
        dispatch({type: TRENDING_PRODUCT, payload: res.data});
    }
};

export const getCategoryProducts = () => async dispatch => {
    const res = await http.get(categoryProducts);
    if (res.status === 200) {
        dispatch({type: CATEGORY_PRODUCTS, payload: res.data});
    } else {
        dispatch({type: CATEGORY_PRODUCTS, payload: res.data});
    }
};
