import React, { Component, Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import "../assets/scss/main.scss";

import {aboutUs, category, details, home} from "../utils/routes";
import Home from "./home/Home";
import Category from "./category/category";
import AboutUs from "./aboutUs/aboutUs";

import Navbar from "./common/header/Navbar";
import Footer from "./common/footer/Footer";
import NavbarTop from "./common/header/NavbarTop";
import MainMenu from "./common/header/mainMenu";
import ProductDetails from "./productDetails/productDetails";

class App extends Component {
    render() {
        return (
            <Fragment>
                <NavbarTop />
                <Navbar />
                <MainMenu />
                {/* <CartPage /> */}
                <Switch>
                    <Route path={home} component={Home} exact/>
                    <Route path={`${category}/:categoryId/:categoryName`} component={Category} exact/>
                    <Route path={`${category}/:categoryId/:categoryName/:subCategoryId/:subCategoryName`} component={Category} exact/>
                    <Route path={`${category}/:categoryId/:categoryName/:subCategoryId/:subCategoryName/:reSubCategoryId/:reSubCategoryName`} component={Category} exact/>
                    <Route path={aboutUs} component={AboutUs} exact/>
                    <Route path={`${details}/:category/:title`} component={ProductDetails} exact/>
                </Switch>
                <Footer />
            </Fragment>
        );
    }
}

export default App;
