import React, {Component, Suspense} from 'react';
import {connect} from "react-redux";

import {getSliders} from "../../redux/actions/slider";
import {getTrendingProducts, getCategoryProducts} from "../../redux/actions/home";

const HomeSlider = React.lazy(() => import('../common/slider/homeSlider'));
const Product = React.lazy(() => import('../common/products/product'));


class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mainSliders: [],
            trendingProducts: [],
            categoryProducts: [],
        }

    }

    componentDidMount = async () => {
        const {mainSliders, trendingProducts, categoryProducts} = this.state;
        if (mainSliders.length < 1) {
            await this.props.dispatch(getSliders());
            await this.setState({mainSliders: this.props.mainSliders});
        }

        if (trendingProducts.length < 1) {
            await this.props.dispatch(getTrendingProducts());
            await this.setState({trendingProducts: this.props.trendingProduct});
        }

        if (categoryProducts.length < 1) {
            await this.props.dispatch(getCategoryProducts());
            await this.setState({categoryProducts: this.props.categoryProducts.data});
        }
    };

    render() {
        const {mainSliders, trendingProducts, categoryProducts} = this.state;
        return (
            <>
                <Suspense fallback={<div>Loading...</div>}>
                    <HomeSlider sliders={mainSliders}/>
                </Suspense>

                <div className="productListWrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="produt_top">
                                    <h2 className="sec-title">Trending Products</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {trendingProducts && trendingProducts.length > 0 && trendingProducts.slice(0, 4).map((item, index) =>
                                <Suspense fallback={<div key={index}>Loading...</div>}>
                                    <Product item={item}/>
                                </Suspense>
                            )}
                        </div>
                    </div>
                </div>

                {categoryProducts && categoryProducts.length > 0 && categoryProducts.map((category, i) =>
                    <div className="productListWrap" key={i}>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="produt_top">
                                        <h2 className="sec-title">{category.label}</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {category.subCategories && category.subCategories.length > 0 && category.subCategories.slice(0, 4).map((product, index) =>
                                    <Suspense fallback={<div key={index}>Loading...</div>}>
                                        <Product item={product} category={category.label}/>
                                    </Suspense>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    }
}

const mapStateToProps = state => ({
    mainSliders: state.mainSliderReducer,
    trendingProduct: state.trendingProduct,
    categoryProducts: state.categoryProductReducer,
});

export default connect(mapStateToProps)(Home);
