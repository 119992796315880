import http from "../../../utils/http";
import {productDetail} from "../../../utils/endPoint";
import {PRODUCT_DETAIL} from "../index";

export const getProductDetail = (title) => async dispatch => {
    const res = await http.get(`${productDetail}/${title}`
    );
    if (res.status === 200) {
        dispatch({type: PRODUCT_DETAIL, payload: res.data});
    } else {
        dispatch({type: PRODUCT_DETAIL, payload: res});
    }
};
