import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Tab, Tabs} from "react-bootstrap";
import {Link, useLocation, useParams} from 'react-router-dom';
import {getProductsByCategory} from "../Product/redux/actions";
import {SpinnerLoader} from "../Loader/SpinnerLoader";
import {imgBackendUrl} from "../../utils/baseUrl"
import {details} from "../../utils/routes";

function Category() {
    const dispatch = useDispatch();
    const {data, error, loading} = useSelector(state => state.productsReducers);
    const {categoryId, categoryName, subCategoryId, subCategoryName, reSubCategoryId, reSubCategoryName} = useParams();
    const [brands, setBrands] = useState([])

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchMyApi().then(r => r).catch(c => c);
    }, [categoryId, subCategoryId, reSubCategoryId]);

    async function fetchMyApi() {
        if (categoryId && !subCategoryId && !reSubCategoryId) {
            await dispatch(getProductsByCategory(categoryId))
        } else if (categoryId && subCategoryId && !reSubCategoryId) {
            await dispatch(getProductsByCategory(categoryId, subCategoryId))
        } else if (categoryId && subCategoryId && reSubCategoryId) {
            await dispatch(getProductsByCategory(categoryId, subCategoryId, reSubCategoryId))
        }
    }

    useEffect(() => {
        let brandsFilter = Object.keys(data).length > 0 && data.product.map(item => item.brand);
        brandsFilter && setBrands([...new Set(brandsFilter)])
    }, [data]);

    return (
        <div className="CategoryWrap">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-12 mt-3 mb-3 bg-light p-2">
                        <Link to={"/"} className="btn btn-sm btn-info">Home</Link> >
                        Category {categoryName !== undefined && '> ' + categoryName} {subCategoryName !== undefined && '> ' + subCategoryName} {reSubCategoryName !== undefined && '> ' + reSubCategoryName}
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-3 col-sm-12 col-12">
                        <div className="sideBarFilterWrap">
                            <div className="produt_top">
                                <h2 className="sec-title">Filters</h2>
                            </div>
                            <div className="filterSingleItem">
                                <h6 className="filterTitle">Brands</h6>
                                <ul className="filterList">
                                    {loading && <SpinnerLoader/>}
                                    {brands && brands.length > 0 && brands.map((brand, i) =>
                                        <li key={i}>
                                            <input type="radio" value={brand} id={brand}/> <label
                                            htmlFor={brand}>{brand}</label>
                                        </li>
                                    )}
                                </ul>
                            </div>
                            {/*<div className="filterSingleItem">*/}
                            {/*    <h6 className="filterTitle">Color</h6>*/}
                            {/*    <ul className="filterList">*/}
                            {/*        <li>*/}
                            {/*            <a href="#">Black</a>*/}
                            {/*            <span className="count">(1)</span>*/}
                            {/*        </li>*/}
                            {/*        <li>*/}
                            {/*            <a href="#">Acer</a>*/}
                            {/*            <span className="count">(1)</span>*/}
                            {/*        </li>*/}
                            {/*        <li>*/}
                            {/*            <a href="#">Acer</a>*/}
                            {/*            <span className="count">(1)</span>*/}
                            {/*        </li>*/}
                            {/*        <li>*/}
                            {/*            <a href="#">Acer</a>*/}
                            {/*            <span className="count">(1)</span>*/}
                            {/*        </li>*/}
                            {/*        <li>*/}
                            {/*            <a href="#">Acer</a>*/}
                            {/*            <span className="count">(1)</span>*/}
                            {/*        </li>*/}
                            {/*    </ul>*/}
                            {/*</div>*/}
                        </div>

                        <div className="latestProductWrap">
                            <div className="produt_top">
                                <h2 className="sec-title">Latest Products</h2>
                            </div>
                            <div className="latestProductList">

                                {/* <PriceRangeSlider /> */}
                                {loading && <SpinnerLoader/>}
                                {error !== '' && <div className="alert alert-error">{error.message}</div>}
                                {Object.keys(data).length > 0 && data.product.slice(5, 10).map((item, i) =>
                                    <div className="latestProductSingleItem" key={i}>
                                        <div className="row">
                                            <div className="col-4">
                                                <div className="latestProductImg">
                                                    <Link to={`${details}/${categoryName}/${item.title}`}>
                                                        <img src={imgBackendUrl + item.image_one}
                                                             alt="latestProductImg"
                                                             className="img-fluid"/></Link>
                                                </div>
                                            </div>
                                            <div className="col-8">
                                                <div className="productInfo">
                                                    <h6><Link
                                                        to={`${details}/${categoryName}/${item.title}`}>{item.title}</Link>
                                                    </h6>
                                                    <div className="reviewStar">
                                                        <i className="fas fa-star done"/>
                                                        <i className="fas fa-star done"/>
                                                        <i className="fas fa-star done"/>
                                                        <i className="fas fa-star-half-alt done"/>
                                                        <i className="far fa-star"/>
                                                    </div>
                                                    <div className="price">
                                                        <div className="prePrice">
                                                            <del>{item.price} BDT</del>
                                                        </div>
                                                        <div className="NowPrice">
                                                            <p>{item.price} BDT</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-9 col-sm-12 col-12">
                        <div className="categaryTabWrap">
                            <h3>Shop</h3>
                            <div className="categaryTab">
                                <Tabs defaultActiveKey="grid" transition={false} id="noanim-tab-example">
                                    {/* <Tab eventKey="home" title="<i className='fa fa-th'></i>"> */}
                                    <Tab eventKey="grid" title="Grid View">

                                        <div className="tabMainContent gridView">
                                            <div className="row">
                                                {Object.keys(data).length > 0 && data.product.map((item, k) =>
                                                    <div key={k}
                                                         className="col-md-3 col-lg-3 col-sm-12 col-12 productSingleItem">
                                                        <div className="product">
                                                            <p className="product-category">
                                                                <Link
                                                                    to={`${details}/${categoryName}/${item.title}`}>{item.brand}</Link>
                                                            </p>
                                                            <p className="product-title">
                                                                <a href="#">{item.title}</a>
                                                            </p>
                                                            <div className="product-image">
                                                                <Link to={`${details}/${categoryName}/${item.title}`}>
                                                                    <img className="img-fluid"
                                                                         src={imgBackendUrl + item.image_one}
                                                                         alt={item.title}/>
                                                                </Link>
                                                            </div>
                                                            <p className="product-price">
                                                                <small className="price">{item.price} BDT</small>
                                                                <small className="product-cart">
                                                                    <a href="#"><i
                                                                        className="fas fa-cart-arrow-down"/></a>
                                                                </small>
                                                            </p>
                                                            <div className="product-footer">
                                                                <div className="wish-list">
                                                                    <a href="#"><i className="mr mr-favorites"/>Wishlist</a>
                                                                </div>
                                                                <div className="compare">
                                                                    <a href="#"><i
                                                                        className="mr mr-compare"/>Compare</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="list" title="List View">
                                        <div className="tabMainContent ListView">
                                            <div className="row">

                                                {Object.keys(data).length > 0 && data.product.map((item, l) =>
                                                    <div key={l} className="col-12 productSingleItem">
                                                        <div className="product">
                                                            <div className="row">
                                                                <div className="col-md-3 col-sm-6 col-12">

                                                                    <div className="product-image">
                                                                        <Link
                                                                            to={`${details}/${categoryName}/${item.title}`}>{item.title}
                                                                            <img className="img-fluid"
                                                                                 src={imgBackendUrl + item.image_one}
                                                                                 alt={item.title}/>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-5 col-sm-6 col-12">
                                                                    <div className="productetailsBox">
                                                                        <p className="product-category">
                                                                            <a href="#">{categoryName}</a>
                                                                        </p>
                                                                        <p className="product-title">
                                                                            <Link
                                                                                to={`${details}/${categoryName}/${item.title}`}>{item.title}</Link>
                                                                        </p>
                                                                    </div>
                                                                    <div className="reviewStar">
                                                                        <i className="fas fa-star done"/>
                                                                        <i className="fas fa-star done"/>
                                                                        <i className="fas fa-star done"/>
                                                                        <i className="fas fa-star-half-alt done"/>
                                                                        <i className="far fa-star"/>
                                                                        <Link to="/"> (3 customer reviews)</Link>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4 col-sm-6 col-12">
                                                                    <p className="product-price">
                                                                        <small
                                                                            className="price">{item.price} BDT</small>
                                                                        <small className="product-cart">
                                                                            <a href="#"><i
                                                                                className="fas fa-cart-arrow-down"/></a>
                                                                        </small>
                                                                    </p>
                                                                    <div className="product-footer">
                                                                        <div className="wish-list">
                                                                            <a href="#"><i
                                                                                className="mr mr-favorites"/>Wishlist</a>
                                                                        </div>
                                                                        <div className="compare">
                                                                            <a href="#"><i
                                                                                className="mr mr-compare"/>Compare</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Category;
