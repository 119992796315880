import http from "../../../utils/http";
import {mainSlider, trendingProducts} from "../../../utils/endPoint";
import {SLIDERS, TRENDING_PRODUCT} from "../index";

export const getSliders = () => async dispatch => {
    const res = await http.get(mainSlider);
    if (res.status === 200) {
        dispatch({type: SLIDERS, payload: res.data});
    } else {
        dispatch({type: SLIDERS, payload: res.data});
    }
};

