export const home = "/";
export const category = "/category";
export const singleProduct = "/single-product";
export const aboutUs = "/about-us";
export const checkout = "/checkout";
export const contact = "/contact";
export const faq = "/faq";
export const shoppingCard = "/shopping-card";
export const termsAndConditions = "/terms-and-condition";
export const details = "/details";
export const blog = "/blog";
export const login = "/login";
export const logout = "/logout";
