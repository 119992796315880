import {PRODUCT_DETAIL} from "../../actions";


export const productDetailReducer = (state = [], action) => {
    switch (action.type) {
        case PRODUCT_DETAIL:
            return action.payload;
        default:
            return state
    }
};
