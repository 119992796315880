import {combineReducers} from "redux";
import {navigationReducer} from "./navigationReducers"
import {mainSliderReducer} from "./sliders/mainSliderReducer"
import {trendingProduct} from "./home/index"
import {categoryProductReducer} from "./categoryProducts/categoryProductReducers"
import {productDetailReducer} from  "./Product"
import {productsReducers} from "../../components/Product/redux/reducers"

export default combineReducers({
    navigationReducer,
    mainSliderReducer,
    trendingProduct,
    categoryProductReducer,
    productDetailReducer,
    productsReducers
});
