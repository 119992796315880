import {CATEGORY_PRODUCTS} from "../../actions";

export const categoryProductReducer = (state = [], action) => {
    switch (action.type) {
        case CATEGORY_PRODUCTS:
            return action.payload;
        default:
            return state
    }
};
