import React, { Component } from 'react';
import Breadcrumb from "../common/breadcrumb/Breadcrumb";
import { Link } from 'react-router-dom';
import { Accordion, Button, Tabs, Tab } from 'react-bootstrap';
import ReactImageMagnify from 'react-image-magnify';
import {connect} from "react-redux";
import {imgBackendUrl} from "../../utils/baseUrl";
import {getProductDetail} from "../../redux/actions/Products/index.";

export class ProductDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            title: null,
            category: null,
            productDetail: null

        }
    }

    async componentDidMount() {
        await window.scrollTo(0, 0);
       document.title =  this.props.match.params.title;
      await this.props.dispatch(getProductDetail(this.props.match.params.title));
        await this.setState({
            title: this.props.match.params.title,
            category: this.props.match.params.category,
            productDetail: this.props.productDetail.product
        });
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.match.params.title !== this.props.match.params.title) {
            await this.props.dispatch(getProductDetail(this.props.match.params.title));
            await this.setState({
                title: this.props.match.params.title,
                category: this.props.match.params.category,
                productDetail: this.props.productDetail.product
            });
            document.title =  this.props.match.params.title;
        }
    }

    render() {
        const { title, category, productDetail} = this.state;
        if (!productDetail){
            return 'Loading ... ';
        }
        return (
            <div className="productDetailsWrap">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <Breadcrumb title={title} categoryName={category} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 col-sm-12 col-12 sideBarMenuWrap">
                            <div className="sideBarMenuWidget">
                                <ul className="sideBarMenuContent">
                                    <li className="productCat">
                                        <ul className="sideBarMenu">
                                            <li>
                                                <Accordion>
                                                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                                        Category
                                                        <span className="icon">{'>'}</span>
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="0" className="collapseMenu">
                                                        <ul>
                                                            <li> <Link to="/">Category  <span> (10)</span> </Link> </li>
                                                            <li> <Link to="/">Category </Link> </li>
                                                            <li> <Link to="/">Category </Link> </li>
                                                            <li> <Link to="/">Category </Link> </li>
                                                            <li> <Link to="/">Category </Link> </li>
                                                            <li> <Link to="/">Category </Link> </li>
                                                            <li> <Link to="/">Category </Link> </li>
                                                        </ul>
                                                    </Accordion.Collapse>
                                                </Accordion>
                                            </li>
                                        </ul>
                                        <ul className="categoryListMenu">
                                            <li>
                                                <Link to="/"> Category  <span> (10)</span>  </Link>
                                                <ul className="categoryList">
                                                    <li><Link to="/"> Category  <span> (10)</span> </Link></li>
                                                    <li><Link to="/"> Category </Link></li>
                                                    <li><Link to="/" className="active"> Category <span> (10)</span> </Link></li>
                                                    <li><Link to="/"> Category </Link></li>
                                                    <li><Link to="/"> Category </Link></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-9 col-sm-12 col-12">
                            <div className="productDetailsBody row">
                                <div className="col-md-5 col-sm-5 col-12">
                                    <ReactImageMagnify {...{
                                        smallImage: {
                                            alt: { title },
                                            isFluidWidth: true,
                                            src: imgBackendUrl + productDetail.image_one
                                        },
                                        largeImage: {
                                            src: imgBackendUrl + productDetail.image_one,
                                            width: 1200,
                                            height: 1800
                                        }
                                    }} className="productZoom" />
                                </div>
                                <div className="col-md-7 col-sm-7 col-12">
                                    <div className="productDetailsContent">
                                        <p className="categoryTags"><Link to="/">{category}</Link></p>
                                        <h4 className="productTitle">{title}</h4>
                                        <div className="reviewStar">
                                            <i className="fas fa-star done"/>
                                            <i className="fas fa-star done"/>
                                            <i className="fas fa-star done"/>
                                            <i className="fas fa-star-half-alt done"/>
                                            <i className="far fa-star"/>
                                            <Link to="/"> (3 customer reviews)</Link>
                                        </div>
                                        <p className="Availability">Availability: <span> 68 in stock</span></p>
                                        <hr />
                                        <div className="wishListLink">
                                            <p>
                                                <a href="#"><i className="mr mr-favorites" />Wishlist</a>
                                                <a href="#"><i className="mr mr-compare" />Compare</a>
                                            </p>
                                        </div>
                                        <div dangerouslySetInnerHTML={{
                                            __html: productDetail.features
                                        }}></div>
                                        <h2 className="prodductPrice">{productDetail.price} TK</h2>
                                        <div className="orderNow">
                                            <div className="quantityInput">
                                                <input type="number" className="quantity" min="1" max="100" name="quantity" title="Qty" size="4" inputMode="numeric"  />
                                            </div>
                                            <div className="callNow">
                                                <button className="btn btn-info animated bounceIn">Order Now: +88 01874 034 216-20</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="productDetailsTab">
                                <Tabs defaultActiveKey="Description" id="uncontrolled-tab-example">
                                    <Tab eventKey="Description" title="Description">
                                        <div dangerouslySetInnerHTML={{
                                            __html: productDetail.description
                                        }}></div>
                                    </Tab>
                                    <Tab eventKey="Specifications" title="Specifications">
                                        <div dangerouslySetInnerHTML={{
                                            __html: productDetail.specification
                                        }}></div>
                                    </Tab>
                                    <Tab eventKey="Questions" title="Questions">
                                        <h6>Drop Us a Questions</h6>
                                        <form action="#" method="post" className="QuestionsForm ">
                                            <div className="row">
                                                <div className="col-sm-6 col-12">
                                                    <div className="form-group">
                                                        <input type="text" className="form-control" placeholder="Your Name *" />
                                                    </div>
                                                    <div className="form-group">
                                                        <input type="text" className="form-control" placeholder="Your Email *" />
                                                    </div>
                                                    <div className="form-group">
                                                        <input type="text" className="form-control" placeholder="Your Phone
                                                    Number *"/>
                                                    </div>
                                                    <div className="form-group mb-0">
                                                        <button className="btn btn-success" type="submit">Send Questions</button>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-12">
                                                    <div className="form-group">
                                                        <textarea className="form-control" placeholder="Your Questions *">
                                                        </textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

const mapStateToProps = state => ({
    categoryProducts: state.categoryProductReducer,
    productDetail: state.productDetailReducer,
});

export default connect(mapStateToProps)(ProductDetails);
