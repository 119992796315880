import React, {Component} from 'react';

class FooterNewsLetter extends Component {
    render() {
        return (
            <div className="footer_newsletter">
                <div className="container">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-12">
                                <div className="footer-newsletter-inner">
                                    <div className="newsletter-content">
                                        <h5 className="newsletter-title">Sign up to Newsletter</h5>
                                        <span className="newsletter-marketing-text">...and receive <b>discount for first shopping</b>
                                        </span>
                                    </div>
                                    <div className="newsletter-form">
                                        <form action="#">
                                            <div className="input-group">
                                                <input type="text" className="form-control"
                                                       placeholder="Enter your email address"/>
                                                <span className="input-group-btn">
                                                    <button className="btn btn-secondary" type="button">Sign Up</button>
                                                </span>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FooterNewsLetter;