export const fetchRequest = (type) => {
    return {
        type: type,
    }
};

export const fetchSuccess = (type, data) => {
    return {
        type: type,
        payload: data
    }
};

export const fetchFailure = (type, error) => {
    return {
        type: type,
        payload: error
    }
};
