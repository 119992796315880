// set top header
export const NAVIGATION = "NAVIGATION";

//Sliders
export const SLIDERS = "SLIDERS";


export const TRENDING_PRODUCT = "TRENDING_PRODUCT";

export const CATEGORY_PRODUCTS = "CATEGORY_PRODUCTS";
export const PRODUCT_DETAIL = "PRODUCT_DETAIL";
