import React, {Component} from 'react';
import {Link} from "react-router-dom";

export class NavbarTop extends Component {
    render() {
        return (
            <div className="navbar_top">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <nav className="navbar navbar-expand-lg">
                                <ul className="contact_link">
                                    <li><Link to="#"><i className="fas fa-phone-alt"/>+88 01874 034 216-20</Link></li>
                                    <li><Link to="#"><i className="far fa-envelope"/>Info@Comptech.Solutions</Link></li>
                                </ul>
                                <ul className="ml-auto navTopRight">
                                    <li><Link to="#"><i className="fas fa-map-marker-alt"/>Store Locator</Link></li>
                                    <li><Link to="#"><i className="fas fa-truck-moving"/>Track Your Order</Link></li>
                                    <li>BDT<select>
                                        <option value="0">TAKA (BD)</option>
                                    </select></li>
                                    <li><Link to="#"><i className="fas fa-user"/>Register or Sign in</Link></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default NavbarTop;
