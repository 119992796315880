import axios from "axios";
import backEndApiV1 from "./apis/backEndApiV1";

const get = async endpoint => {
    return backEndApiV1.get(endpoint)
        .then(res => {
            return res
        })
        .catch(err => {
            if (err.response !== undefined && err.response) {
                // Server responded with a status other than 200
                return err.response;
            } else if (err.request) {
                // Request was made but no response
                return err.request;
            } else {
                return err.message;
            }
        });
};

const post = async endpoint => {
    return backEndApiV1.post(endpoint)
        .then(res => {
            return res
        })
        .catch(err => {
            if (err.response !== undefined && err.response) {
                // Server responded with a status other than 200
                return err.response;
            } else if (err.request) {
                // Request was made but no response
                console.error(err.request);
            } else {
                console.error(err.message);
            }
        });
};


const postWithFullUrl = async (endpoint, body) => {


    const response = await axios.post(endpoint, body, {});
    try {
        if (response.status === 200) {
            return response.data;
        }
    } catch (ex) {
        return ex;
    }
};

const postReqWithBody = async (endpoint, body, queryParams) => {
    const response = await backEndApiV1.post(endpoint, body, {
        params: queryParams,
    });
    try {
        return response.data;
    } catch (ex) {
        return ex;
    }
};

export default {
    get,
    post,
    postReqWithBody,
    postWithFullUrl,
};
